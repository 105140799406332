import { createSelector } from '@reduxjs/toolkit';
import {
  notFound,
  StaticDataRouteOption,
  AnyRoute,
  AnySearchValidator,
  BeforeLoadContextOptions,
  RouteContext,
  LoaderFnContext,
  NotFoundError,
} from '@tanstack/react-router';
import { RootRouteContext } from '../routes/__root';
import { iamApi } from '../services/iamApi';
import { AccountPermissions, AccountPrivileges } from '../utils/constants/access-control';

export class RouteAccessControlError<TParams extends Record<string, unknown> | { _error: string }> extends Error {
  constructor(
    private routeParams: TParams,
    private privileges?: AccountPrivileges,
    private permissions?: AccountPermissions,
  ) {
    super();
  }

  /**
   * Returns the data that caused the error, which is part of the structure of the {error} prop of 'notFoundComponent'.
   * The root route receives this data and can use it to render a custom error component if necessary.
   */
  get data() {
    return {
      privileges: this.privileges,
      permissions: this.permissions,
      routeParams: this.routeParams,
    };
  }
}

export function isRouteAccessControlError<
  T extends { types: { allParams: Record<string, unknown> } } = {
    types: {
      allParams: {
        // eslint-disable-next-line @stylistic/js/max-len
        _error: "The generic parameter in isRouteAccessControlError<T> must be explicitely provided: 'isRouteAccessControlError<typeof Route>'";
      };
    };
  },
>(err: unknown): err is RouteAccessControlError<T['types']['allParams']> {
  return err instanceof RouteAccessControlError;
}

function getStoreData({
  context,
  params,
}: BeforeLoadContextOptions<AnyRoute, AnySearchValidator, { accountId: string }, unknown, () => RootRouteContext>) {
  return createSelector(iamApi.endpoints.getUserInfo.select({ account_id: params.accountId }), ({ data }) => ({
    permissions: data?.account.permissions ?? [],
    privileges: data?.account.privileges ?? [],
  }))(context.store.getState());
}

export function withRouteAccessControl<
  T extends {
    options: {
      staticData?: StaticDataRouteOption;
      beforeLoad?: (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ctx: BeforeLoadContextOptions<any, any, any, any, any>,
      ) => Promise<RouteContext> | RouteContext | void;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      loader?: (ctx: LoaderFnContext<AnyRoute, any, any, any, any, any>) => void;
      onError?: (err: unknown) => void;
    };
  },
>(route: T): T {
  const originalBeforeLoad = route.options.beforeLoad;
  route.options.beforeLoad = function beforeLoad(
    ctx: BeforeLoadContextOptions<AnyRoute, AnySearchValidator, { accountId: string }, unknown, () => RootRouteContext>,
  ) {
    if (!(this.staticData?.privileges?.length || this.staticData?.permissions?.length)) {
      throw new Error(
        "withRouteAccessControl(): 'privileges' and/or 'permissions' are required when using this HOC. " +
          "Define them in 'staticData' within route.",
      );
    }
    const { privileges, permissions } = getStoreData(ctx);

    const missingPrivileges = this.staticData.privileges?.filter((p) => !privileges.includes(p));
    const missingPermissions = this.staticData.permissions?.filter((p) => !permissions.includes(p));

    // Check if the user hasn't got the 'CLOUD_RBAC' privilege, and there are missing permissions.
    // Remove this check when we do not depend on the 'CLOUD_RBAC' privilege to apply permission checking.
    if (!privileges.includes('CLOUD_RBAC') && missingPermissions?.length) {
      missingPermissions.length = 0;
    }

    if (missingPrivileges?.length || missingPermissions?.length) {
      return {
        __notFoundError: notFound(new RouteAccessControlError(ctx.params, missingPrivileges, missingPermissions)),
      };
    }
    return originalBeforeLoad?.(ctx);
  };
  const originalLoader = route.options.loader;
  route.options.loader = function loader(ctx: LoaderFnContext<AnyRoute>) {
    const context = ctx.context as { __notFoundError?: NotFoundError };
    if (context.__notFoundError) {
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw context.__notFoundError;
    }
    return originalLoader?.(ctx);
  };
  return route;
}
