import { ParseParams, z } from 'zod';
import { fromZodError } from 'zod-validation-error';

export const CLUSTER_NAME_HELPER_TXT = 'Can only contain alphanumeric characters, hyphens and underscores.';

export const REG_EXPS: Record<string, { regExp: RegExp; errorMessage: string }> = {
  name: {
    regExp: /^[\p{L}\p{Nl}\p{Nd}\p{M}\p{Pc}\s-]+$/gu,
    errorMessage: 'String contains invalid character(s)',
  },
  postcode: {
    regExp: /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/i,
    errorMessage: 'Invalid postal code',
  },
  alpha_2: {
    regExp: /^[a-z]{2}$/i,
    errorMessage: 'Invalid country code',
  },
  clusterName: {
    regExp: /^[a-z0-9_-]+$/i,
    errorMessage: CLUSTER_NAME_HELPER_TXT,
  },
  kubernetesResourceName: {
    // See https://kubernetes.io/docs/concepts/overview/working-with-objects/names/
    regExp: /^[a-z0-9]([a-z0-9-.]{0,61}[a-z0-9])*$/i,
    errorMessage: 'Can only contain letters, numbers, hyphens and dots, and must start and end with a letter or number',
  },
  kubernetesNamespace: {
    regExp: /^[a-z0-9]([a-z0-9-]{0,61}[a-z0-9])*$/i,
    errorMessage: 'Can only contain letters, numbers and hyphens, and must start and end with a letter or number',
  },
  kuberneteSecret: {
    regExp: /^[A-Za-z0-9_.-]{1,64}$/i,
    errorMessage: 'Can only contain letters, numbers, dots, hyphens and underscores',
  },
  chartRepositoryUrl: {
    regExp: /^oci:\/\/[a-zA-Z0-9.\-/]+$/i,
    errorMessage: 'Chart repository URL must start with oci://',
  },
  containerRegistryUrl: {
    regExp: /^[a-zA-Z0-9.\-/]+$/i,
    errorMessage: 'Container registry URL must not contain a protocol',
  },
};

const MAX_KUBERNETES_NAME_LENGTH = 63;
const MAX_URL_LENGTH = 2048;
const getExceededMaxLengthErrorMsg = (max: number) => `Must be at most ${max} characters`;
const getBelowMinLengthErrorMsg = (min: number) => `Must be at least ${min} character${min > 1 ? 's' : ''}`;

const _validators = {
  // 4-256 characters, letters, numbers, marks, punctuation, spaces, hyphens
  name: z.string().trim().min(4).max(256).regex(REG_EXPS.name.regExp, REG_EXPS.name.errorMessage),
  // ISO 3166-1 alpha-2
  alpha2: z
    .string()
    .trim()
    .regex(REG_EXPS.alpha_2.regExp, REG_EXPS.alpha_2.errorMessage)
    .transform((value) => value.toUpperCase()),
  // 2-12 characters, letters, numbers, spaces, hyphens
  postcode: z.string().regex(REG_EXPS.postcode.regExp, REG_EXPS.postcode.errorMessage),
  clusterName: z
    .string()
    .trim()
    .min(4, getBelowMinLengthErrorMsg(4))
    .max(64, getExceededMaxLengthErrorMsg(64))
    .regex(REG_EXPS.clusterName.regExp, REG_EXPS.clusterName.errorMessage),
  kubernetesResourceName: z
    .string()
    .min(1, getBelowMinLengthErrorMsg(1))
    .max(MAX_KUBERNETES_NAME_LENGTH, getExceededMaxLengthErrorMsg(MAX_KUBERNETES_NAME_LENGTH))
    .regex(REG_EXPS.kubernetesResourceName.regExp, REG_EXPS.kubernetesResourceName.errorMessage),
  kubernetesNamespace: z
    .string()
    .min(1, getBelowMinLengthErrorMsg(1))
    .max(MAX_KUBERNETES_NAME_LENGTH, getExceededMaxLengthErrorMsg(MAX_KUBERNETES_NAME_LENGTH))
    .regex(REG_EXPS.kubernetesNamespace.regExp, REG_EXPS.kubernetesNamespace.errorMessage),
  kubernetesSecret: z
    .string()
    .min(1, getBelowMinLengthErrorMsg(1))
    .max(MAX_KUBERNETES_NAME_LENGTH, getExceededMaxLengthErrorMsg(MAX_KUBERNETES_NAME_LENGTH))
    .regex(REG_EXPS.kuberneteSecret.regExp, REG_EXPS.kuberneteSecret.errorMessage),
  containerRegistryUrl: z
    .string()
    .min(1)
    .max(MAX_URL_LENGTH, getExceededMaxLengthErrorMsg(MAX_URL_LENGTH))
    .regex(REG_EXPS.containerRegistryUrl.regExp, REG_EXPS.containerRegistryUrl.errorMessage),
  chartRepositoryUrl: z
    .string()
    .min(1)
    .max(MAX_URL_LENGTH, getExceededMaxLengthErrorMsg(MAX_URL_LENGTH))
    .regex(REG_EXPS.chartRepositoryUrl.regExp, REG_EXPS.chartRepositoryUrl.errorMessage),
};

const getOptionalStringValidator = (validator: z.ZodString) => z.union([validator, z.string().length(0)]).optional();

// Add optional version of some validators
export const validators = Object.assign({}, _validators, {
  optionalName: getOptionalStringValidator(_validators.name).transform((e) => (e === '' ? undefined : e)),
  optionalKubernetesResourceName: getOptionalStringValidator(_validators.kubernetesResourceName),
  optionalKubernetesSecret: getOptionalStringValidator(_validators.kubernetesSecret),
  optionalChartRepositoryUrl: getOptionalStringValidator(_validators.chartRepositoryUrl),
  optionalContainerRegistryUrl: getOptionalStringValidator(_validators.containerRegistryUrl),
});

export const validate = (validator: z.ZodTypeAny, input: unknown, params?: Partial<ParseParams>) => {
  const parsedInput = validator.safeParse(input);
  if (parsedInput.error) {
    return fromZodError(validator.safeParse(input, params).error!, { prefix: null }).message;
  }
  return null;
};
