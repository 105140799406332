import { queryOptions, useMutation } from '@tanstack/react-query';
import { FromPathParams, MutationOptions, QueryData, QueryOptions, queryKey, toPathParams } from './utils';
import { router } from '../../router';
import { client } from '../client';
import { queryClient } from '../query-client';

export const endpoints = {
  paymentAvailable: '/accounts/{account_id}/payments/available',
  paymentInformation: '/accounts/{account_id}/payments/information',
  paymentInformationById: '/accounts/{account_id}/payments/{payment_information_id}',
  paymentSession: '/accounts/{account_id}/payments/session/{session_id}',
  paymentInvoices: '/accounts/{account_id}/payments/invoices',
  paymentAvailableMethods: '/accounts/{account_id}/payments/available-methods',
  paymentMethod: '/accounts/{account_id}/payments/method',
  paymentChangeMethod: '/accounts/{account_id}/payments/change-payment-method/{payment_information_id}',
  paymentMarketplaceRegistration: '/accounts/{account_id}/mp/s/registration',
  paymentInformationTaxId: '/accounts/{account_id}/payments/information/{payment_information_id}/tax-id',
  paymentInformationBillingAddress: '/accounts/{account_id}/payments/information/{payment_information_id}/address',
} as const;

export type PaymentInformationDto = QueryData<typeof endpoints.paymentInformation>;
export type PaymentInformationType = PaymentInformationDto['type_'];
export type PaymentSessionDto = QueryData<typeof endpoints.paymentSession>;
export type PaymentInvoicesDto = QueryData<typeof endpoints.paymentInvoices>;

export const getPaymentAvailableQuery = (params: FromPathParams<QueryOptions<typeof endpoints.paymentAvailable>>) =>
  queryOptions<QueryData<typeof endpoints.paymentAvailable>>({
    queryKey: queryKey.get(endpoints.paymentAvailable, params),
    retry: 0,
  });

export const getPaymentInformationQuery = (params: FromPathParams<QueryOptions<typeof endpoints.paymentInformation>>) =>
  queryOptions<PaymentInformationDto>({
    queryKey: queryKey.get(endpoints.paymentInformation, params),
  });

export const getPaymentSessionQuery = (params: FromPathParams<QueryOptions<typeof endpoints.paymentSession>>) =>
  queryOptions<PaymentSessionDto>({
    queryKey: queryKey.get(endpoints.paymentSession, params),
    staleTime: 0,
  });

export const getInvoicesQuery = (params: FromPathParams<QueryOptions<typeof endpoints.paymentInvoices>>) =>
  queryOptions<PaymentInvoicesDto>({
    queryKey: queryKey.get(endpoints.paymentInvoices, params),
  });

export const getPaymentMethodQuery = (params: FromPathParams<QueryOptions<typeof endpoints.paymentMethod>>) =>
  queryOptions<QueryData<typeof endpoints.paymentMethod>>({
    queryKey: queryKey.get(endpoints.paymentMethod, params),
  });

export const getPaymentMethodsQuery = (
  params: FromPathParams<QueryOptions<typeof endpoints.paymentAvailableMethods>>,
) =>
  queryOptions<QueryData<typeof endpoints.paymentAvailableMethods>>({
    queryKey: queryKey.get(endpoints.paymentAvailableMethods, params),
  });

export const useUpdatePaymentMethodMutation = <
  TOptions extends MutationOptions<typeof endpoints.paymentChangeMethod, 'put'>,
  TParams extends FromPathParams<TOptions>,
>(
  params: Pick<TParams, 'account_id'>,
) =>
  useMutation({
    mutationFn: async (args: Pick<TParams, 'payment_information_id'>) => {
      const { data } = await client.PUT(endpoints.paymentChangeMethod, toPathParams({ ...params, ...args }));
      return data!;
    },
    onSuccess: async ({ account_id }) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKey.get(endpoints.paymentMethod, { account_id }),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKey.get(endpoints.paymentInformation, { account_id }),
        }),
      ]);
      await router.invalidate();
    },
  });

export const useDeletePaymentInformationMutation = <
  TOptions extends MutationOptions<typeof endpoints.paymentInformationById, 'delete'>,
  TParams extends FromPathParams<TOptions>,
>(
  params: TParams,
) =>
  useMutation({
    mutationFn: async () => {
      const { data } = await client.DELETE(endpoints.paymentInformationById, toPathParams(params));
      return data!;
    },
    onSuccess: async () => {
      const { account_id } = params;

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKey.get(endpoints.paymentInformation, { account_id }),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKey.get(endpoints.paymentAvailable, { account_id }),
        }),
      ]);
      queryClient.removeQueries({
        queryKey: queryKey.get(endpoints.paymentAvailableMethods, { account_id }),
      });
      queryClient.removeQueries({
        queryKey: queryKey.get(endpoints.paymentMethod, { account_id }),
      });
      await router.invalidate();
    },
  });

export const useCreateMarketplaceRegistrationMutation = <
  TOptions extends MutationOptions<typeof endpoints.paymentMarketplaceRegistration, 'post'>,
  TParams extends FromPathParams<TOptions>,
  TVariables extends TOptions['body'],
>(
  params: TParams,
) =>
  useMutation({
    mutationFn: async (body: TVariables) => {
      const { data } = await client.POST(endpoints.paymentMarketplaceRegistration, { ...toPathParams(params), body });
      return data!;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKey.get(endpoints.paymentInformation, params),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKey.get(endpoints.paymentAvailable, params),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKey.get(endpoints.paymentMethod, params),
        }),
      ]);
      await router.invalidate();
    },
  });

export const useUpdatePaymentInformationAddressMutation = <
  TOptions extends MutationOptions<typeof endpoints.paymentInformationBillingAddress, 'put'>,
  TParams extends FromPathParams<TOptions>,
  TVariables extends TOptions['body'],
>(
  params: TParams,
) =>
  useMutation({
    mutationFn: async (body: TVariables) => {
      const { data } = await client.PUT(endpoints.paymentInformationBillingAddress, { ...toPathParams(params), body });
      return data!;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKey.get(endpoints.paymentInformation, { account_id: params.account_id }),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKey.get(endpoints.paymentAvailable, { account_id: params.account_id }),
        }),
      ]);
      await router.invalidate();
    },
  });

export const useUpdatePaymentInformationTaxIdMutation = <
  TOptions extends MutationOptions<typeof endpoints.paymentInformationTaxId, 'put'>,
  TParams extends FromPathParams<TOptions>,
  TVariables extends TOptions['body'],
>(
  params: TParams,
) =>
  useMutation({
    mutationFn: async (body: TVariables) => {
      const { data } = await client.PUT(endpoints.paymentInformationTaxId, { ...toPathParams(params), body });
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKey.get(endpoints.paymentInformation, { account_id: params.account_id }),
      });
      await router.invalidate();
    },
  });

export const useDeletePaymentInformationTaxIdMutation = <
  TOptions extends MutationOptions<typeof endpoints.paymentInformationTaxId, 'delete'>,
  TParams extends FromPathParams<TOptions>,
>(
  params: TParams,
) =>
  useMutation({
    mutationFn: async () => {
      const { data } = await client.DELETE(endpoints.paymentInformationTaxId, toPathParams(params));
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKey.get(endpoints.paymentInformation, { account_id: params.account_id }),
      });
      await router.invalidate();
    },
  });
