import { ComponentSchema } from './api-schema-utils';
import { BOOKING_PACKAGE_TYPE_FREE } from '../components/Clusters/ClusterSetup/helpers';
import { CLOUD_PROVIDER_MAP } from '../components/Clusters/constants';
import { Package } from '../services/bookingApi';

export type Cluster = ComponentSchema<'ClusterOut'>;
export type PrivateRegion = ComponentSchema<'PydanticPrivateRegionOut'>;
export type HybridCloudEnvConfigIn = ComponentSchema<'PydanticPrivateRegionConfigIn'>;
export type PrivateRegionStatus = ComponentSchema<'PydanticPrivateRegionStatus'>;
export type PrivateRegionComponentPhase = ComponentSchema<'PrivateRegionStatusComponentStatusPhase'>;
export type BackupSchedule = ComponentSchema<'PydanticSchedule'>;
export type Backup = ComponentSchema<'PydanticBackup'>;
export type ClusterResources = ComponentSchema<'ClusterOut'>['resources'];
export type ClusterResource = ComponentSchema<'ClusterResources'>;
export type QdrantConfiguration = ComponentSchema<'QdrantConfiguration-Output'>;
export type LogLevels = ComponentSchema<'PydanticPrivateRegionConfigIn'>['log_level'];
export type ClusterUsageMetrics = ComponentSchema<'ClusterUsageMetricsResponse'>;
export type ClusterMetricsOverview = ComponentSchema<'ClusterMetricOverviewResponse'>;
export type MetricsResponse = ClusterUsageMetrics | ClusterMetricsOverview;

export type NodesUsageMetrics = ClusterUsageMetrics['nodes'];

export type NodesMetrics = ClusterMetricsOverview['nodes'];
export type NodeMetrics = NonNullable<NodesMetrics>[number];
export type MetricType = Exclude<keyof NodeMetrics, 'node_id'>;

export function getFreeTierPackage(packages: Package[]) {
  return packages.find((entry) => entry.type === BOOKING_PACKAGE_TYPE_FREE);
}

/**
 * Check if the given cluster is a free tier
 */
export function isClusterFreeTier(cluster?: Cluster) {
  const bookingPackage = cluster?.configuration?.node_configuration.package;
  if (!bookingPackage) {
    return false;
  }
  return bookingPackage.type === BOOKING_PACKAGE_TYPE_FREE;
}

/**
 * Check if user already created a free tier cluster
 */
export function isFreeTierCreated(clusters?: Cluster[]) {
  if (!clusters?.length) {
    return false;
  }
  return clusters.some(isClusterFreeTier);
}

export function getNodeNumber(clusterId: string, nodeId: string): string {
  return nodeId.replace(`qdrant-${clusterId}-`, '');
}

/**
 * Check if the given cluster is a free tier
 */
export const isClusterHybridCloud = (cluster?: Cluster) => cluster?.cloud_provider === CLOUD_PROVIDER_MAP.PRIVATE;

export const getNonHybridClusters = (clusters: Cluster[] = []) =>
  clusters.filter((cluster) => !isClusterHybridCloud(cluster));

export const getClusterEndpoint = (cluster?: Cluster, fallback = '') => cluster?.state?.endpoint ?? fallback;

export const isClusterHealthy = (cluster: Cluster | null) => cluster?.state?.phase === 'Healthy';

// Type guard for the first type of Response
export const isClusterUsageMetrics = (response: MetricsResponse): response is ClusterUsageMetrics =>
  response?.nodes != null && response.nodes.length > 0 && Array.isArray(response.nodes[0].cpu);

export const hasClusterRbacEnabled = (cluster?: Cluster) =>
  cluster?.configuration?.qdrant_configuration?.service?.jwt_rbac ?? false;
