import { createRoute } from '@tanstack/react-router';
import { withRouteAccessControl } from '../../../router/withRouteAccessControl';
import { Route as ClustersRoute } from '../clusters_';

export const Route = withRouteAccessControl(
  createRoute({
    meta: () => [
      {
        title: 'Clusters',
      },
    ],
    staticData: {
      permissions: ['read:clusters'],
    },
    getParentRoute: () => ClustersRoute,
    path: '/',
  }),
).lazy(() => import(/* webpackChunkName: "clusters" */ './index.lazy').then(({ Route }) => Route));
