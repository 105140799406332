import { createRoute } from '@tanstack/react-router';
import { Route as AccountDashboardRoute } from '.';
import { withRouteAccessControl } from '../../router/withRouteAccessControl';

export const Route = withRouteAccessControl(
  createRoute({
    meta: () => [
      {
        title: 'Backups',
      },
    ],
    staticData: {
      permissions: ['read:backups', 'read:clusters'],
    },
    getParentRoute: () => AccountDashboardRoute,
    path: 'backups',
  }),
).lazy(() => import(/* webpackChunkName: "backups" */ './backups.lazy').then(({ Route }) => Route));
