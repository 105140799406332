import { Account, UserInfo } from '../services/iamApi';

export class AccountMissingError extends Error {
  static displayName = 'AccountMissingError';
  override readonly name = 'AccountMissingError';

  constructor(private readonly userInfo: UserInfo) {
    super();
  }

  override get message() {
    return this.userInfo.default_account_id ?? this.userInfo.accounts?.[0]?.id ?? '';
  }
}

export function isAccountMissingError(error: unknown): error is AccountMissingError {
  return (
    typeof error === 'object' && error != null && 'name' in error && error.name === AccountMissingError.displayName
  );
}

/*
 * Returns the current account for the user.
 * If `accountId` is provided, it will return the account with the given ID.
 * If not, it will return the default account for the user.
 * If the accountId causes the account to be missing, it will throw an `AccountMissingError`.
 * This error can be caught and used to redirect the user to a different account (see IAM service).
 */
export function getCurrentAccount(userInfo: UserInfo, accountId?: string) {
  const id = accountId ?? userInfo.default_account_id;
  const predicate = id
    ? (account: Account) => account.id === id
    : (account: Account) => account.owner_email === userInfo.email;

  const account = userInfo.accounts?.find(predicate);
  if (!account) {
    throw new AccountMissingError(userInfo);
  }
  return account;
}
