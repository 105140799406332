import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { ReactElement, ReactNode, Ref, forwardRef, memo } from 'react';

export type GlobalAlertProps = {
  title?: ReactNode;
  description?: ReactNode;
  actions?: { handler: (close: () => void) => void; text: string }[];
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const GlobalAlertDialog = memo(function GlobalAlertDialog({
  isOpen,
  close,
  title,
  description,
  actions,
}: Partial<GlobalAlertProps> & { isOpen: boolean; close: () => void }) {
  return (
    <Dialog
      data-testid="global-alert"
      open={isOpen}
      TransitionComponent={Transition}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          close();
        }
      }}
      aria-labelledby="global-alert-title"
      aria-describedby="global-alert-description"
    >
      <DialogTitle id="global-alert-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="global-alert-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions ? (
          actions.map((action, index) => (
            <Button key={index} onClick={() => action.handler(close)}>
              {action.text}
            </Button>
          ))
        ) : (
          <Button onClick={close}>Close</Button>
        )}
      </DialogActions>
    </Dialog>
  );
});
