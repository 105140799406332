import { queryOptions, useMutation } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import {
  FromPathParams,
  FromQueryParams,
  MutationOptions,
  QueryData,
  queryKey,
  QueryOptions,
  toPathParams,
} from './utils';
import { useRootContext } from '../../router/hooks';
import { client } from '../client';

export const endpoints = {
  accountApiKeys: '/accounts/{account_id}/auth/api-keys',
  accountApiKey: '/accounts/{account_id}/auth/api-keys/{api_key_id}',
} as const;

export type AccountApiKeysDto = QueryData<typeof endpoints.accountApiKeys>;

export const getAccountApiKeys = (
  params: FromPathParams<QueryOptions<typeof endpoints.accountApiKeys>>,
  query?: FromQueryParams<QueryOptions<typeof endpoints.accountApiKeys>>,
) =>
  queryOptions<AccountApiKeysDto>({
    queryKey: queryKey.get(endpoints.accountApiKeys, params, query),
  });

export const useCreateApiKeyMutation = <
  TOptions extends MutationOptions<typeof endpoints.accountApiKeys, 'post'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: TParams,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    // Not needed for now: https://github.com/TanStack/query/discussions/6093#discussioncomment-7240423
    // mutationKey: queryKey.post(endpoints.accountApiKeys, params),
    mutationFn: async (body: TVariables) => {
      const { data } = await client.POST(endpoints.accountApiKeys, {
        ...toPathParams(params),
        body,
      });
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.accountApiKeys, params) });
      await router.invalidate();
    },
  });
};

export const useDeleteApiKeyByIdMutation = <
  TOptions extends MutationOptions<typeof endpoints.accountApiKey, 'delete'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
>(
  params: Pick<TParams, 'account_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    // Not needed for now: https://github.com/TanStack/query/discussions/6093#discussioncomment-7240423
    // mutationKey: queryKey.delete(endpoints.accountApiKey, params),
    mutationFn: async ({ api_key_id }: Pick<TParams, 'api_key_id'>) => {
      const { data } = await client.DELETE(endpoints.accountApiKey, toPathParams({ ...params, api_key_id }));
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.accountApiKeys, params) });
      await router.invalidate();
    },
  });
};
