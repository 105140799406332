import { queryOptions, useMutation } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { FromPathParams, MutationOptions, QueryData, QueryOptions, queryKey, toPathParams } from './utils';
import { useRootContext } from '../../router/hooks';
import { iamApi } from '../../services/iamApi';
import { client } from '../client';

export const endpoints = {
  roles: '/accounts/{account_id}/auth/roles',
  detail: '/accounts/{account_id}/auth/roles/{role_id}',
  permissions: '/accounts/{account_id}/auth/permissions',
} as const;

export const getAccountRolesQuery = (params: FromPathParams<QueryOptions<typeof endpoints.roles>>) =>
  queryOptions<QueryData<typeof endpoints.roles>>({
    queryKey: queryKey.get(endpoints.roles, params),
  });

export type AccountPermissionsData = QueryData<typeof endpoints.permissions>;

export const getAccountPermissionsQuery = (params: FromPathParams<QueryOptions<typeof endpoints.permissions>>) =>
  queryOptions<AccountPermissionsData>({
    queryKey: queryKey.get(endpoints.permissions, params),
  });

export const usePostAccountRolesMutation = <
  TOptions extends MutationOptions<typeof endpoints.roles>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: TParams,
) => {
  const { store, queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationKey: queryKey.post(endpoints.roles, params),
    mutationFn: async (body: TVariables) => {
      const { data } = await client.POST(endpoints.roles, { ...toPathParams(params), body });
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.roles, params) });
      store.dispatch(iamApi.util.invalidateTags(['UserInfo']));
      await router.invalidate();
    },
  });
};

export const useDeleteAccountRoleMutation = <
  TOptions extends MutationOptions<typeof endpoints.detail, 'delete'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
>(
  params: TParams,
) => {
  const { store, queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationKey: queryKey.delete(endpoints.detail, params),
    mutationFn: async () => {
      const { data } = await client.DELETE(endpoints.detail, toPathParams(params));
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [endpoints.roles],
      });
      store.dispatch(iamApi.util.invalidateTags(['UserInfo']));
      await router.invalidate();
    },
  });
};

export const usePatchAccountRoleMutation = <
  TOptions extends MutationOptions<typeof endpoints.detail, 'patch'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: TParams,
) => {
  const { store, queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationKey: queryKey.patch(endpoints.detail, params),
    mutationFn: async (body: TVariables) => {
      const { data } = await client.PATCH(endpoints.detail, { ...toPathParams(params), body });
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [endpoints.roles],
      });
      store.dispatch(iamApi.util.invalidateTags(['UserInfo']));
      await router.invalidate();
    },
  });
};
