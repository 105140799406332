import { createRoute } from '@tanstack/react-router';
import { Route as ClusterIdRoute } from '../$clusterId';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Cluster',
    },
  ],
  getParentRoute: () => ClusterIdRoute,
  path: '/',
}).lazy(() => import(/* webpackChunkName: "cluster-id-index" */ './index.lazy').then(({ Route }) => Route));
