import { queryOptions, useMutation } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { useRootContext } from '../../../router/hooks';
import { ComponentSchema } from '../../../utils/api-schema-utils';
import { client } from '../../client';
import { endpoints as accountEndpoints } from '../account';
import { FromPathParams, MutationOptions, QueryData, QueryOptions, queryKey, toPathParams } from '../utils';

export const endpoints = {
  clusterByAccountId: '/accounts/{account_id}/clusters',
  clusterById: '/accounts/{account_id}/clusters/{cluster_id}',
  clusterScalable: '/accounts/{account_id}/clusters/{cluster_id}/scalable',
  clusterJwts: '/accounts/{account_id}/clusters/{cluster_id}/auth/jwts',
  clusterJwt: '/accounts/{account_id}/clusters/{cluster_id}/auth/jwts/{cluster_jwt_id}',
  clusterCollections: '/accounts/{account_id}/clusters/{cluster_id}/query/collections',
} as const;

export type ClusterDto = QueryData<typeof endpoints.clusterById>;
export type ClusterScalableDto = QueryData<typeof endpoints.clusterScalable>;
export type ClusterCollectionsDto = QueryData<typeof endpoints.clusterCollections>;
export type ClusterJwtsDto = QueryData<typeof endpoints.clusterJwts>;
export type ClusterJwtDto = ClusterJwtsDto[number];
export type ClusterJwtCreateDto = MutationOptions<typeof endpoints.clusterJwts, 'post'>['body'];
export type ClusterJwtPayloadAccess = ComponentSchema<'PydanticClusterJWTPayloadAccess'>;
export type QdrantDbCollectionsResponse = ComponentSchema<'QdrantDbCollectionsResponse'>;
export type TokenPayload = ClusterJwtDto['jwt_payload'];
export type TokenPayloadAccess = TokenPayload['access'];
// ... or: Exclude<ClusterJwtDto['jwt_payload']['access'], 'r' | 'm'>

export const getClusterScalableQuery = (params: FromPathParams<QueryOptions<typeof endpoints.clusterScalable>>) =>
  queryOptions<ClusterScalableDto>({ queryKey: queryKey.get(endpoints.clusterScalable, params), retry: false });

export const getClusterCollectionsQuery = (params: FromPathParams<QueryOptions<typeof endpoints.clusterCollections>>) =>
  queryOptions<ClusterCollectionsDto>({ queryKey: queryKey.get(endpoints.clusterCollections, params), retry: false });

export const getClusterJwts = (params: FromPathParams<QueryOptions<typeof endpoints.clusterJwts>>) =>
  queryOptions<ClusterJwtsDto>({
    queryKey: queryKey.get(endpoints.clusterJwts, params),
  });

export const useDeleteClusterJwtMutation = <
  TOptions extends MutationOptions<typeof endpoints.clusterJwt, 'delete'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
>(
  params: Pick<TParams, 'account_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationKey: queryKey.delete(endpoints.clusterJwt, params),
    mutationFn: async (args: Pick<TParams, 'cluster_jwt_id' | 'cluster_id'>) => {
      const { data } = await client.DELETE(endpoints.clusterJwt, {
        params: {
          path: {
            account_id: params.account_id,
            cluster_id: args.cluster_id,
            cluster_jwt_id: args.cluster_jwt_id,
          },
        },
      });
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.clusterJwts, params) });
      await router.invalidate();
    },
  });
};

export const useCreateClusterJwtMutation = <
  TOptions extends MutationOptions<typeof endpoints.clusterJwts, 'post'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
>(
  params: Pick<TParams, 'account_id' | 'cluster_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationKey: queryKey.post(endpoints.clusterJwts, params),
    mutationFn: async (body: ClusterJwtCreateDto) => {
      const { data } = await client.POST(endpoints.clusterJwts, {
        ...toPathParams(params),
        body,
      });
      return data!;
    },
    onSuccess: async () => {
      // Invalidate the cluster jwts query and the account's.
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.clusterJwts, params) }),
        queryClient.invalidateQueries({
          queryKey: [accountEndpoints.accountJwts, toPathParams(params, { filterKeys: ['account_id'] })],
        }),
      ]);
      await router.invalidate();
    },
  });
};
