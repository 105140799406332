import { createRoute } from '@tanstack/react-router';
import { getPaymentInformationQuery } from '../../../../api/services/payment';
import { withRouteAccessControl } from '../../../../router/withRouteAccessControl';
import { MIDDOT_SYMBOL } from '../../../../utils/constants';
import { Route as HybridCloudOnboardingRoute } from '../onboarding_';

export const Route = withRouteAccessControl(
  createRoute({
    meta: () => [
      {
        title: `Hybrid Cloud ${MIDDOT_SYMBOL} Onboarding`,
      },
    ],
    staticData: {
      permissions: ['read:payment_information'],
    },
    getParentRoute: () => HybridCloudOnboardingRoute,
    path: 'payment',
    beforeLoad: ({ params: { accountId } }) => ({
      paymentInformationQueryOptions: getPaymentInformationQuery({ account_id: accountId }),
    }),
    loader({ context: { queryClient, paymentInformationQueryOptions } }) {
      void queryClient.prefetchQuery(paymentInformationQueryOptions);
    },
  }),
).lazy(() =>
  import(/* webpackChunkName: "hybrid-cloud-onboarding-payment" */ './payment.lazy').then(({ Route }) => Route),
);
