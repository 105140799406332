import { createRoute } from '@tanstack/react-router';
import { Route as HybridCloudEnvIdRoute } from './$hybridCloudEnvId';
import { withRouteAccessControl } from '../../../router/withRouteAccessControl';
import { MIDDOT_SYMBOL } from '../../../utils/constants';

export const Route = withRouteAccessControl(
  createRoute({
    meta: () => [
      {
        title: `Hybrid Cloud Environment ${MIDDOT_SYMBOL} Edit`,
      },
    ],
    staticData: {
      permissions: ['write:hybrid_cloud_environments'],
    },
    getParentRoute: () => HybridCloudEnvIdRoute,
    path: 'edit',
  }),
).lazy(() =>
  import(/* webpackChunkName: "hybrid-cloud-env-id-edit" */ './$hybridCloudEnvId.edit.lazy').then(({ Route }) => Route),
);
