import { Box, Breadcrumbs, Container, Grid, Typography, styled } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { DASHBOARD_LAYOUT_GAP } from '.';
import { PageFooter } from '../Common/PageFooter';

const StyledAccountDashboardMain = styled('div')<{ hasTopMenu?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 1;
  min-height: ${({ hasTopMenu }) => (hasTopMenu ? `calc(100vh - ${DASHBOARD_LAYOUT_GAP})` : '100vh')};
`;

// this component is used to render the page title and the action buttons
export const AccountDashboardMain = ({
  children,
  title,
  buttons,
  hasTopMenu = true,
  breadcrumbs,
}: {
  children: ReactNode;
  title?: string;
  buttons?: ReactElement[];
  hasTopMenu?: boolean;
  breadcrumbs?: ReactElement[];
}) => (
  <StyledAccountDashboardMain hasTopMenu={hasTopMenu}>
    <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
      <Container maxWidth="xl">
        {Boolean(breadcrumbs?.length) && (
          <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ mt: -4, mb: 1 }}>
            {breadcrumbs}
          </Breadcrumbs>
        )}
        {(title ?? buttons) && (
          <Box sx={{ mb: 4 }}>
            <Grid container={true} justifyContent="space-between" spacing={1}>
              {title && (
                <Grid item={true}>
                  <Typography variant="h4">{title}</Typography>
                </Grid>
              )}
              {/** show action buttons */}
              {buttons && (
                <Grid item={true} display="flex" alignItems="center">
                  {buttons.map((button, index) => (
                    <Box key={index}>{button}</Box>
                  ))}
                </Grid>
              )}
            </Grid>
          </Box>
        )}
        {/** page content */}
        <Box>{children}</Box>
      </Container>
    </Box>
    <PageFooter />
  </StyledAccountDashboardMain>
);

/** @see https://github.com/TanStack/router/blob/main/examples/react/kitchen-sink-file-based/src/components/Breadcrumbs.tsx  */
/*
export function useBreadcrumbs() {
  const matches = useMatches();

  if (matches.some((match) => match.status === 'pending')) {
    return;
  }

  const matchesWithCrumbs = matches.filter((match) => isMatch(match, 'loaderData.crumb'));

  return matchesWithCrumbs.map((match, i) =>
    i + 1 < matchesWithCrumbs.length ? (
      <Link
        key={i}
        from={match.fullPath}
        sx={(theme) => ({
          textDecoration: 'none',
          color: theme.palette.text.primary,
        })}
      >
        {match.loaderData?.crumb}
      </Link>
    ) : (
      <Typography key={i} color="text.primary">
        {match.loaderData?.crumb}
      </Typography>
    ),
  );
}*/
