import { createRoute } from '@tanstack/react-router';
import { Route as AccountDashboardRoute } from '.';
import { getAccountApiKeys } from '../../api/services/authentication';
import { clusterApi } from '../../services/clustersApi';
import { getNonHybridClusters } from '../../utils/cluster-utils';
import { getAccountUser } from '../_authenticated/_account';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Overview',
    },
  ],
  getParentRoute: () => AccountDashboardRoute,
  path: 'overview',
  beforeLoad: async ({ context: { store, queryClient }, params: { accountId } }) => {
    const {
      account: { permissions, privileges },
    } = await getAccountUser(store, {
      account_id: accountId,
    });

    if (privileges?.includes('CLOUD_RBAC')) {
      const canWriteClusters = permissions.includes('write:clusters');
      const canReadClusters = permissions.includes('read:clusters');
      const canWriteApiKeys = permissions.includes('write:api-keys');
      const canReadAPIKeys = permissions.includes('read:api-keys');

      // The Overview stepper needs all of the following permissions to be able to show the stepper cards
      // the design needs to be thought out more.
      if (!canWriteClusters || !canWriteApiKeys || !canReadAPIKeys || !canReadClusters) {
        return {
          hasMissingPermissions: true,
          accountApiKeysQueryOptions: null,
        };
      }
    }

    const accountApiKeysQueryOptions = getAccountApiKeys({ account_id: accountId });

    const [clusters, apiKeys] = await Promise.all([
      store.dispatch(clusterApi.endpoints.getClustersByAccountId.initiate({ account_id: accountId })),
      queryClient.fetchQuery(accountApiKeysQueryOptions),
    ]);

    return {
      shouldShowWelcomeStepper: getNonHybridClusters(clusters?.data)?.length === 0 || apiKeys.length === 0,
      accountApiKeysQueryOptions,
    };
  },
}).lazy(() => import(/* webpackChunkName: "overview" */ './overview.lazy').then(({ Route }) => Route));
