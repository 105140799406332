import { createRoute } from '@tanstack/react-router';
import { Route as AccountDashboardRoute } from '..';
import { getHybridCloudEnvsQuery } from '../../../api/services/cluster/hybrid-cloud';
import { hybridCloudSearchSchema } from '../../../router/utils';
import { withRouteAccessControl } from '../../../router/withRouteAccessControl';

export const Route = withRouteAccessControl(
  createRoute({
    meta: () => [
      {
        title: 'Hybrid Cloud',
      },
    ],
    staticData: {
      permissions: ['read:hybrid_cloud_environments'],
    },
    validateSearch: hybridCloudSearchSchema,
    getParentRoute: () => AccountDashboardRoute,
    path: 'hybrid-cloud',
    beforeLoad: ({ params: { accountId } }) => ({
      hybridCloudEnvsQuery: getHybridCloudEnvsQuery({ account_id: accountId }),
    }),
    loader: ({ context: { queryClient, hybridCloudEnvsQuery } }) => {
      void queryClient.prefetchQuery(hybridCloudEnvsQuery);
    },
  }),
).lazy(() => import(/* webpackChunkName: "hybrid-cloud" */ './index.lazy').then(({ Route }) => Route));
