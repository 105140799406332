import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { isJsonApiClientError, isApiClientError, QdrantErrorCode } from '../../../api/client';
import { ClusterDto, getClusterScalableQuery } from '../../../api/services/cluster';

type UseGetClusterScalableResult =
  | {
      isScalable: false;
      errorMessage?: string;
    }
  | { isScalable: true; errorMessage?: never };

/**
 * Hook to check if a cluster is scalable at the time of this function invocation, taking into account the
 * cluster's state and payment type, and perhaps some other heuristics.
 */
export const useGetClusterScalable = (cluster: ClusterDto): UseGetClusterScalableResult => {
  const { error, isError, isPending } = useQuery(
    useMemo(
      () => ({
        ...getClusterScalableQuery({ account_id: cluster.account_id!, cluster_id: cluster.id }),
        // The query will be refetched every time the component mounts, regardless of the query' stale time.
        // Defaults to `QueryClient.defaultOptions.query.staleTime`.
        refetchOnMount: 'always',
        // When this query's cache becomes unused or inactive, that cache data will be garbage-collected immediately.
        gcTime: 0,
      }),
      [cluster],
    ),
  );

  if (isPending) {
    return { isScalable: false };
  }

  if (!isError) {
    return { isScalable: true };
  }

  return { isScalable: false, errorMessage: getClusterScalableError(error) };
};

export function getClusterScalableError(error: unknown) {
  let errorMessage = 'An error occurred while checking if the cluster is scalable';

  if (isApiClientError(error)) {
    if (isJsonApiClientError(error)) {
      switch (error.error.detail) {
        case QdrantErrorCode.CLUSTER_NOT_ACTIONABLE:
          errorMessage = 'Cluster is busy and cannot be scaled yet';
          break;
        case QdrantErrorCode.FREE_CLUSTER_PAYMENT_TYPE_PROVIDER_MISMATCH:
          errorMessage =
            'To scale up a free tier cluster, you need use the ' +
            "same payment method as the cloud provider's marketplace";
          break;
      }
    }
  }

  return errorMessage;
}
