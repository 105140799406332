import CloseIcon from '@mui/icons-material/Close';
import { Portal, Snackbar, Alert, Button, IconButton } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { SettingsDto } from '../../../api/services/core';

export const VersionAlert = () => {
  const { data: tag } = useQuery({ queryKey: ['settings'], select: ({ tag }: SettingsDto) => tag });

  if (!tag) {
    return null;
  }
  return <VersionAlertContent imageTag={tag} />;
};

function VersionAlertContent({ imageTag }: { imageTag: string }) {
  const [initialImageTag, setInitialImageTag] = useState(() => imageTag);
  const onClose = useCallback(() => setInitialImageTag(imageTag), [imageTag]);

  if (imageTag === initialImageTag) {
    return null;
  }
  return (
    <Portal>
      <Snackbar open={true}>
        <Alert
          severity="info"
          action={
            <>
              <Button
                size="small"
                onClick={() => {
                  Sentry.addBreadcrumb({
                    category: 'reload',
                    message: 'VersionAlert: REFRESH button clicked to reload the page',
                    level: 'info',
                  });
                  window.location.reload();
                }}
              >
                REFRESH
              </Button>
              <IconButton onClick={onClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
          sx={{
            '&>.MuiAlert-action': {
              padding: 0,
              marginLeft: 1,
            },
          }}
        >
          A new version of Qdrant Cloud is available
        </Alert>
      </Snackbar>
    </Portal>
  );
}
