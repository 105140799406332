import { createRoute, redirect } from '@tanstack/react-router';
import { getClusterScalableQuery } from '../../../api/services/cluster';
import { withRouteAccessControl } from '../../../router/withRouteAccessControl';
import { clusterApi } from '../../../services/clustersApi';
import { isFetchApiError, parseFetchError } from '../../../services/helpers';
import { Route as ClustersRoute } from '../clusters_';

export const Route = withRouteAccessControl(
  createRoute({
    getParentRoute: () => ClustersRoute,
    path: '$clusterId',
    staticData: {
      permissions: ['read:clusters'],
    },
    beforeLoad: async ({ context: { store }, params }) => {
      const { clusterId, accountId } = params;

      try {
        await store
          .dispatch(clusterApi.endpoints.getClusterById.initiate({ account_id: accountId, cluster_id: clusterId }))
          .unwrap();
      } catch (error) {
        const parsedError = parseFetchError(error);
        if (isFetchApiError(parsedError) && parsedError.originalStatus === 403) {
          throw redirect({
            to: '/accounts/$accountId/clusters',
            params: { accountId },
            replace: true,
          });
        }
      }

      return {
        clusterScalableQueryOptions: getClusterScalableQuery({
          account_id: accountId,
          cluster_id: clusterId,
        }),
      };
    },
  }),
).lazy(() => import(/* webpackChunkName: "cluster-id" */ './$clusterId.lazy').then(({ Route }) => Route));
