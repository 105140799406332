import { queryOptions, useMutation } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { FromPathParams, MutationOptions, QueryData, queryKey, QueryOptions, toPathParams } from './utils';
import { client } from '../client';

export const endpoints = {
  accountJwts: '/accounts/{account_id}/auth/jwts',
  accountById: '/accounts/{account_id}',
} as const;

export const getAccountJwts = (params: FromPathParams<QueryOptions<typeof endpoints.accountJwts>>) =>
  queryOptions<QueryData<typeof endpoints.accountJwts>>({
    queryKey: queryKey.get(endpoints.accountJwts, params),
  });

export const useDeleteAccountMutation = <
  TOptions extends MutationOptions<typeof endpoints.accountById, 'delete'>,
  TParams extends FromPathParams<TOptions>,
>() => {
  const router = useRouter();

  return useMutation({
    mutationFn: async (params: TParams) => {
      const { data } = await client.DELETE(endpoints.accountById, toPathParams(params));
      return data!;
    },
    onSuccess: () => router.buildAndCommitLocation({ to: '/logout' }),
  });
};
