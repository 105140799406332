import { queryOptions, useMutation } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { useRootContext } from '../../../router/hooks';
import { client } from '../../client';
import { FromPathParams, MutationOptions, QueryData, QueryOptions, queryKey } from '../utils';

export const endpoints = {
  hybridCloudEnv: '/accounts/{account_id}/hybrid-cloud-environments/{hybrid_cloud_env_id}',
  hybridCloudEnvs: '/accounts/{account_id}/hybrid-cloud-environments',
  generateInstallationCommand:
    '/accounts/{account_id}/hybrid-cloud-environments/{hybrid_cloud_env_id}/initial-installation-command',
} as const;

export type HybridCloudEnvDto = QueryData<typeof endpoints.hybridCloudEnv>;

export const getHybridCloudEnvQuery = (params: FromPathParams<QueryOptions<typeof endpoints.hybridCloudEnv>>) =>
  queryOptions<HybridCloudEnvDto>({
    queryKey: queryKey.get(endpoints.hybridCloudEnv, params),
  });

export const getHybridCloudEnvsQuery = (params: FromPathParams<QueryOptions<typeof endpoints.hybridCloudEnvs>>) =>
  queryOptions<QueryData<typeof endpoints.hybridCloudEnvs>>({
    queryKey: queryKey.get(endpoints.hybridCloudEnvs, params),
  });

export const useGenerateHybridCloudEnvInitialInstallationCommandMutation = <
  TOptions extends MutationOptions<typeof endpoints.generateInstallationCommand, 'post'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
>(
  params: Pick<TParams, 'account_id' | 'hybrid_cloud_env_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationKey: queryKey.post(endpoints.generateInstallationCommand, params),
    mutationFn: async () => {
      const { data } = await client.POST(endpoints.generateInstallationCommand, {
        params: {
          path: {
            account_id: params.account_id,
            hybrid_cloud_env_id: params.hybrid_cloud_env_id,
          },
        },
      });
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.hybridCloudEnv, params) });
      await queryClient.invalidateQueries({
        queryKey: queryKey.get(endpoints.hybridCloudEnvs, { account_id: params.account_id }),
      });
      await router.invalidate();
    },
  });
};
