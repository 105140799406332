import { createRoute, redirect } from '@tanstack/react-router';
import { isApiClientError } from '../../../api/client';
import { getHybridCloudEnvQuery } from '../../../api/services/cluster/hybrid-cloud';
import { Route as HybridCloudRoute } from '../hybrid-cloud_';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Hybrid Cloud Environment',
    },
  ],
  getParentRoute: () => HybridCloudRoute,
  path: '$hybridCloudEnvId',
  beforeLoad: async ({ context: { queryClient }, params: { accountId, hybridCloudEnvId } }) => {
    const hybridCloudEnvQuery = getHybridCloudEnvQuery({
      account_id: accountId,
      hybrid_cloud_env_id: hybridCloudEnvId,
    });

    try {
      await queryClient.fetchQuery(hybridCloudEnvQuery);
    } catch (error) {
      if (isApiClientError(error) && error.originalStatus === 404) {
        throw redirect({
          to: '/accounts/$accountId/hybrid-cloud',
          params: { accountId },
          replace: true,
        });
      }
    }

    return { hybridCloudEnvQuery };
  },
}).lazy(() =>
  import(/* webpackChunkName: "hybrid-cloud-env-id" */ './$hybridCloudEnvId.lazy').then(({ Route }) => Route),
);
