import { createRoute } from '@tanstack/react-router';
import { MIDDOT_SYMBOL } from '../../../../utils/constants';
import { Route as HybridCloudOnboardingRoute } from '../onboarding_';

export const Route = createRoute({
  meta: () => [
    {
      title: `Hybrid Cloud ${MIDDOT_SYMBOL} Onboarding`,
    },
  ],
  getParentRoute: () => HybridCloudOnboardingRoute,
  path: 'configuration',
}).lazy(() =>
  import(/* webpackChunkName: "hybrid-cloud-onboarding-configuration" */ './configuration.lazy').then(
    ({ Route }) => Route,
  ),
);
