import { Auth0ContextInterface } from '@auth0/auth0-react';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { QueryClient } from '@tanstack/react-query';
import { Outlet, createRootRouteWithContext, redirect } from '@tanstack/react-router';
import { Suspense, lazy } from 'react';
import { AnalyticsProvider, AnalyticsRoot } from '../components/Analytics/AnalyticsProvider';
import { SUGER_ENTITLEMENT_ID_COOKIE_NAME } from '../components/Billing/SugerRegistration/constants';
import { GlobalAlertProvider } from '../components/Common/GlobalAlert';
import { CookieHubProvider } from '../contexts/cookie-hub-context';
import { META_TITLE, Meta } from '../router/Meta';
import { createRouteErrorComponent } from '../router/createRouteErrorComponent';
import { rootSearchSchema } from '../router/utils';
import { store } from '../store';
import { persistUtmParamsSession, getUtmParamsFromSearch } from '../utils/analytics-utils';
import { Cookies } from '../utils/cookies';

export type AuthContext = Omit<Auth0ContextInterface, 'isLoading' | 'user'> &
  Required<Pick<Auth0ContextInterface, 'user'>>;

export type RootRouteContext = {
  queryClient: QueryClient;
  auth: AuthContext;
  store: typeof store;
};

let didRetrieveUtmParams = false;

export const Route = createRootRouteWithContext<RootRouteContext>()({
  meta: () => [
    {
      title: META_TITLE,
    },
  ],
  validateSearch: rootSearchSchema,
  beforeLoad({ search, context: { queryClient } }) {
    if (!didRetrieveUtmParams) {
      didRetrieveUtmParams = true;
      const utmParams = getUtmParamsFromSearch(search);

      if (utmParams) {
        persistUtmParamsSession(utmParams);
        // Clean up url from UTM params.
        throw redirect({
          to: '.',
          search: (prev) => ({
            ...prev,
            ...Object.keys(utmParams).reduce((acc, key) => ({ ...acc, [key]: undefined }), {}),
          }),
        });
      }
    }

    if (search.sugerEntitlementId) {
      Cookies.set(SUGER_ENTITLEMENT_ID_COOKIE_NAME, search.sugerEntitlementId);
      // Clean up url from sugerEntitlementId param.
      throw redirect({ to: '.', search: (prev) => ({ ...prev, sugerEntitlementId: undefined }) });
    }
    void queryClient.prefetchQuery({ queryKey: ['/config/feature_flags'] });
  },
  component: RootComponent,
  errorComponent: createRouteErrorComponent(),
});

const TanStackDevToolsLazy = lazy(() =>
  import(/* webpackChunkName: 'tanstack-dev-tools' */ '../router/TanStackDevTools').then(({ RouterDevTools }) => ({
    default: RouterDevTools,
  })),
);

function RootComponent() {
  return (
    <GTMProvider state={{ id: window.__QDRANT_CLOUD__.gtm_id }}>
      <CookieHubProvider>
        {({ allowedAnalytics }) => (
          <AnalyticsProvider disabled={!allowedAnalytics}>
            <Meta>
              <GlobalAlertProvider>
                <AnalyticsRoot>
                  <Outlet />
                </AnalyticsRoot>
              </GlobalAlertProvider>
            </Meta>
          </AnalyticsProvider>
        )}
      </CookieHubProvider>
      {window.__QDRANT_CLOUD__.env === 'dev' && (
        <Suspense>
          <TanStackDevToolsLazy />
        </Suspense>
      )}
    </GTMProvider>
  );
}
