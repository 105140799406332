import { createRoute, redirect } from '@tanstack/react-router';
import { withRouteAccessControl } from '../../../router/withRouteAccessControl';
import { MIDDOT_SYMBOL } from '../../../utils/constants';
import { Route as HybridCloudRoute } from '../hybrid-cloud_';

export const Route = withRouteAccessControl(
  createRoute({
    meta: () => [
      {
        title: `Hybrid Cloud Environment ${MIDDOT_SYMBOL} Create`,
      },
    ],
    staticData: {
      permissions: ['write:hybrid_cloud_environments'],
    },
    getParentRoute: () => HybridCloudRoute,
    path: 'create',
    beforeLoad: async ({ context: { queryClient, hybridCloudEnvsQuery }, params: { accountId } }) => {
      const envs = await queryClient.ensureQueryData(hybridCloudEnvsQuery);

      if (envs.length < 1) {
        throw redirect({ to: '/accounts/$accountId/hybrid-cloud', params: { accountId } });
      }
    },
  }),
).lazy(() => import(/* webpackChunkName: "hybrid-cloud-create" */ './create.lazy').then(({ Route }) => Route));
